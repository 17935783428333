.social-link {
    padding-left: 5px;
    text-decoration: none;
    color: white; 
    cursor: pointer;
}

#banner-link {
    text-decoration: none;
    font-weight: bold;
    padding: 7px;
}