.project-pictures {
    height: 255px;
    width: 425px; 
    box-shadow: 0px 10px 16px 0px rgba(158, 125, 125, 0.2);
    border: 1px solid rgba(77, 75, 75, 0.214); 
    padding: 2px; 
}

#link {
    text-decoration: none;
    color: white; 
}

#social-link:hover {
    text-shadow: 0 0 5px rgba(193, 0, 61, 0.747);
}