.homepage-links {
    text-decoration: none;
    color: black;
    background-color: #F0ECF7; 
    margin: 12px; 
    padding: 2px; 
    /* box-shadow: 0 0 10px rgba(0,0,0,0.25);  */
    /* clip-path: inset(0px -15px 0px 2px);  */
    text-align: center;
    font-weight: bold;
    /* border-left: 2px solid rgba(128, 128, 128, 0.281); */
}

.homepage-links:hover {
    color: black;
    text-shadow: 0 0 5px rgba(0, 193, 13, 0.747);
}

.tab-links {
    display: flex;
    justify-content: space-around;
    background-color: #F0ECF7; 
    box-shadow: 0 0 10px rgba(0,0,0,0.25); 
    padding: 5px;
}

.about-me {
    text-align: center;
    justify-content: center;
    align-items: center;
    padding-top: 40px; 
}

h1 {
    color: white; 
}

h2 {
    color: white;  
    font-weight: 400;
}


@keyframes typing {
  from { width: 0 }
  to { width: 100% }
}

@keyframes blink-caret {
  from, to { border-color: transparent }
}


h3 {
    color: white;  
    font-weight: 400;
}

p {
    color: white; 
}

.socials {
    margin: 5px; 
    padding: 5px; 
    text-align: center;
}

#social {
    width: 100px;
}

.social-link {
    text-decoration: none;
    color: white; 
}

.baby-font {
    text-align: center;
}

#closing {
    color: #000000;
    text-shadow: 0 0 5px rgba(0, 193, 13, 0.747);
    text-decoration: none;
    margin-right: 5px;
}

#closing:hover {
    color:#000000;
    text-shadow: 0 0 5px rgba(193, 0, 135, 0.747);
}
