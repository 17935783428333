* {
    font-family: 'Red Hat Mono', monospace; 
}

body {
    background-color: rgb(0, 0, 0);
}

.terminal-outer {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.terminal-tabs { 
    background-color: #f0ecf7; 
    height: 35px;
}

.terminal-inner {
    height: 500px; 
    overflow: auto;
    width: 725px; 
    background-color: #282a36;
    border-radius: 3%;
    box-shadow: 0px 8px 16px 0px rgba(29, 28, 28, 0.2);
    font-family: 'Red Hat Mono', monospace; 
    color: white; 
}

.red {
    width: 15px;
    height: 15px;
    background: #fe5f57;
    border-radius: 50%;
    padding: 5px;
    border: 1px solid #5553530e;
    margin-left: 10px;
    text-decoration: none;
    box-shadow: 0.5px 0.5px 0.5px 0.5px #72717118;
    cursor: pointer; 
}

.yellow {
    width: 15px;
    height: 15px;
    background: #febc2f;
    border-radius: 50%;
    padding: 5px;
    border: 1px solid #5553530e;
    margin-left: 7px;
    box-shadow: 0.5px 0.5px 0.5px 0.5px #73717118;
    /* cursor: pointer;  */
}

.green {
    width: 15px;
    height: 15px;
    background: #28c840;
    border-radius: 50%;
    padding: 5px;
    border: 1px solid #5553530e;
    margin-left: 7px;
    box-shadow: 0.5px 0.5px 0.5px 0.5px #72717118;
    /* cursor: pointer;  */
}

.info {
    color: #a5f8ff;
    font-weight: bold;
    overflow: auto;
    margin: 1px; 
    margin-top: 3px; 
    animation: 
        typing 7s steps(40, end),
        blink-caret .25s step-end infinite;
}


@keyframes typing {
  from { width: 0 }
  to { width: 100% }
}

@keyframes blink-caret {
  from, to { border-color: transparent }
  50% { border-color: orange; }
}


#heading2 {
    color: #e3c0ff;
}

#heading3 {
    color: #fe8f86;
}

.info-inner {
    color: white; 
    overflow: auto;
    margin: 1px; 
}
