.skills-list {
	display: flex;
	flex-wrap: wrap;
	list-style-type: none;
}

.skill-icon {
	flex-direction: column;
	align-items: center;
	padding: 10px;
	font-size: 12px;
	justify-content: center;
	align-items: center;
	text-align: center;
}

.skill-image {
	height: 60px;
	width: 60px;
	padding: 5px;
	margin-right: 5px;
}

#icon-text {
	font-size: 20px;
}